import React from "react"
import "./styles.scss"
import { Link } from "gatsby"

import coinIconSrc from "../../assets/images/terms_icons/coin@3x.png"
import shieldIconSrc from "../../assets/images/terms_icons/shield-2@3x.png"
import surfaceIconSrc from "../../assets/images/terms_icons/surface-1@3x.png"

const ICONS = {
    shield: shieldIconSrc,
    coin: coinIconSrc,
    safe: surfaceIconSrc,
}

function Term({ icon, title, description }) {
    const iconSource = ICONS[icon]

    return (
        <div className={"term-box"}>
            <div className="term-box__header">
                <div className="term-box__image">
                    <img src={iconSource} alt="" />
                </div>
                <div className="term-box__title">{title}</div>
            </div>
            <div className="term-box__body">
                <p>{description}</p>
            </div>
        </div>
    )
}

export default function Policy({ data }) {
    return (
        <div className={"terms-container"}>
            <div className="terms-header">
                <p className="product-title text-center">Privacy policy</p>
            </div>

            <div className="terms-body">
                {data.map((item, i) => (
                    <Term {...item} key={i} />
                ))}
            </div>

            <div className="terms-footer">
                <Link to={"/privacy-policy"}>View privacy policy</Link>
            </div>
        </div>
    )
}
