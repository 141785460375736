export default [
    {
        icon: "safe",
        title: "Your data is kept private & secure",
        description:
            "Any information you create or upload to your user account on Sync.AI Services would be used solely inside your user account environment, only for your benefit and to enable the operation of the Sync.AI Services. Any information you create or upload to your user account on Sync.AI Services would not be shared with any third party, except where: (a) we receive your explicit consent to do so, or (2) where we are legally required to disclose such information.",
    },
    {
        icon: "coin",
        title: "We don’t sell your information",
        description:
            "We do not sell or lease your personal information. Our contact information is available at the bottom of this policy. Please feel free to reach out to us at any time if you have any questions concerning data privacy.",
    },
    {
        icon: "shield",
        title: "Designed to protect your privacy",
        description:
            "Because Sync.AI Services integrate with a variety of content imported, with your authorization, from your email, notes, files, reminders, calendar, phone, messaging, contacts, social media and other services, we take precautions to ensure that your personal information is protected.\n" +
            "\n" +
            "“Third Party Platforms” are software that integrate with our services and that you have authorized to connect with Sync.AI Services. This may include email, calendar, messaging, file storage, phone services, note taking, reminders, social networking, and other cloud services and other similar platforms from which you authorize us to import, search, and access content.\n" +
            "\n" +
            "The security of your personal information is important to us. We follow generally accepted industry standards, including the use of appropriate administrative, physical and technical safeguards, to protect the personal information submitted to us",
    },
]
