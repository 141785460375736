import React, { useEffect } from "react"

import Layout from "../layout/layout"
import SEO from "../components/seo"

import Policy from "../components/Terms"
import termsData from "../DB/terms-data"

import { AnalyticsEventCategories, emitAnalyticsEvent } from "../analytics"

const IndexPage = () => {
    useEffect(() => {
        emitAnalyticsEvent("Page view", AnalyticsEventCategories.PRIVACY)
    }, [])

    return (
        <Layout>
            <SEO title="Policy" />
            <Policy data={termsData} />
        </Layout>
    )
}

export default IndexPage
